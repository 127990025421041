import React from "react";
import { Link } from "gatsby";
import Icon from "react-icons-kit";
import { GatsbyImage } from "gatsby-plugin-image"
import { ic_event } from 'react-icons-kit/md/ic_event'
import { ic_place } from 'react-icons-kit/md/ic_place';
import { ic_assignment } from 'react-icons-kit/md/ic_assignment';
import { cubes } from 'react-icons-kit/fa/cubes'
import { ic_lock_outline } from 'react-icons-kit/md/ic_lock_outline'

import { Box, Button, Text } from "@components/index";
import { JobWrapper, Chip, ChipBlue } from "./job.style";
import { Job } from "common/types";

type Props = {
  data: Job,
  titleStyle?: React.CSSProperties,
  subTitleStyle?: React.CSSProperties,
  buttonApplyDisabledStyle?: React.CSSProperties,
  isSmallScreen: boolean
};

const JobRow = ({ data, titleStyle, subTitleStyle, buttonApplyDisabledStyle, isSmallScreen, companyTextStyle, buttonApplyOnHoldStyle }: Props) => {
  if (isSmallScreen) {
    return (
      <Box>
        <Link to={data.slug}>
          <JobWrapper>
            <Box flexBox flex={1} alignSelf="center" flexDirection="row">
              <div className="post-card-avatar-small">
                {!data.company.brand_consent ?
                  <Icon icon={ic_lock_outline} size={24} />
                    :
                    <GatsbyImage imgStyle={{ borderRadius: '100%'}} className="company-image" image={data.company.logo.localFiles[0].childImageSharp.gatsbyImageData} alt={data.company.name}/>
              }
              </div>
                <div style={{ display: "flex", flexDirection: 'column'}}>
                  <Text content={!data.company.brand_consent ? "PRIVATE COMPANY" : data.company.name.toUpperCase()} {...companyTextStyle} />
                  <Text content={data.title} {...titleStyle} />
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 4 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, alignItems: 'center', textAlign: 'center'}}>
                      <Icon icon={cubes} size={14} style={{ color: "#5268db", marginRight: 2 }} />
                      <Text content={data.experience.join(' / ')} {...subTitleStyle} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, alignItems: 'center', textAlign: 'center' }}>
                      <Icon icon={ic_place} size={14} style={{ color: "#5268db" }} />
                      {data.telecomutting === 'Remote' && <ChipBlue>Remote</ChipBlue>}
                      {data.location.length > 0 && data.location[0] !== 'Full-Remote' && <Text content={data.location[0]} {...subTitleStyle} />}
                      {data.location.length > 1 && <Text content={`, +${data.location.length - 1}`} {...subTitleStyle} />}
                    </div>
                  </div>
                </div>
            </Box>
          </JobWrapper>
        </Link>
      </Box>
    )
  }
  return (
    <Box>
      <Link to={data.slug}>
        <JobWrapper>
          <Box flex={0.05}>
            <div className="post-card-avatar">
              {!data.company.brand_consent ?
                <Icon icon={ic_lock_outline} size={24} />
                :
                <GatsbyImage imgStyle={{ borderRadius: '100%'}} className="company-image" image={data.company.logo.localFiles[0].childImageSharp.gatsbyImageData} alt={data.company.name}/>
            }
            </div>
          </Box>
          <Box flex={0.35}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text content={!data.company.brand_consent ? "PRIVATE COMPANY" : data.company.name.toUpperCase()} {...companyTextStyle} />
                <Text content={data.title} {...titleStyle} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, alignItems: 'center', textAlign: 'center' }}>
                  <Icon icon={cubes} size={14} style={{ color: "#5268db", marginRight: 2 }} />
                  <Text content={data.experience.length === 3 ? 'All levels' : data.experience.join(', ')} {...subTitleStyle} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, alignItems: 'center', textAlign: 'center' }}>
                  <Icon icon={ic_place} size={14} style={{ color: "#5268db" }} />
                  {data.telecomutting === 'Remote' && <ChipBlue>Remote</ChipBlue>}
                  {data.location.length > 0 && data.location[0] !== 'Full-Remote' && <Text content={data.location[0]} {...subTitleStyle} />}
                  {data.location.length > 1 && <Text content={`, +${data.location.length - 1}`} {...subTitleStyle} />}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, alignItems: 'center', textAlign: 'center' }}>
                  <Icon icon={ic_assignment} size={14} style={{ color: "#5268db", marginRight: 2 }} />
                  <Text content={data.employment[0]} {...subTitleStyle} />
                </div>
              </div>
          </Box>
          <Box flex={0.3} alignSelf="center">
            {data.keywords.map((keyword, index) => <Chip key={index}>{keyword}</Chip>)}
          </Box>
          <Box flex={0.2} alignSelf="center" alignItems="center">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>
                <Icon icon={ic_event} size={14} style={{ color: "#5268db", marginRight: 2 }} />
                <Text textAlign="center" content={new Date(data.date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })} {...subTitleStyle} />
              </div>
          </Box>
          <Box flex={0.1} alignSelf="center">
            {data.status === "Open" && <Button colors="successWithBg" title="Details" />}
            {data.status === "Closed" && <Button title="Details" {...buttonApplyDisabledStyle} />}
            {data.status === "On-hold" && <Button title="Details" {...buttonApplyOnHoldStyle} />}
          </Box>
        </JobWrapper>
      </Link>
    </Box>
  );
};

JobRow.defaultProps = {
  buttonApplyDisabledStyle: {
    backgroundColor: 'gray',
    color: 'white',
    borderColor: 'gray'
  },
  buttonApplyOnHoldStyle: {
    backgroundColor: 'yellow',
    color: 'white',
    borderColor: 'yellow'
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center"
  },
  titleStyle: {
    fontSize: ["16px", "16px", "18px", "18px", "18px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    marginBottom: "2px",
    lineHeight: "1.31"
  },
  subTitleStyle: {
    fontSize: ["10px", "14px", "14px", "14px", "14px"],
    color: "#343d48cc",
    marginBottom: "0",
    marginTop: "0",
    lineHeight: "1.31",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    verticalAlign: "center"
  },
  companyTextStyle: {
    fontSize: ["10px", "14px", "14px", "14px", "14px"],
    color: "#5268db",
    letterSpacing: "0.025em",
    marginBottom: "2px",
    lineHeight: "1.31"
  }
};

export default JobRow;
