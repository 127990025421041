import React from 'react';
import Icon from "react-icons-kit";
import { send } from "react-icons-kit/fa/send";
import { refresh } from "react-icons-kit/fa/refresh";
import axios from 'axios';
import Reaptcha from 'reaptcha';
import Bugsnag from '@bugsnag/js';
import { Buffer } from 'buffer';

import { Input, Text, Button, CheckBox } from '@components/index';
import FileUpload from './components/FileUpload';
import { JobApplicationFormWrapper, FormHeader, Row } from './jobapplication.style';
import TEXT from '../../../common/texts';

const API_URL = process.env.GATSBY_API_URL;
const LINKEDIN_FOLLOW_LINK = 'https://www.linkedin.com/pages-extensions/FollowCompany?id=69802562&counter=bottom';
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

type Contact = {
    record_id: string,
    name: string,
    email: string,
    linkedin: string,
    phone: string,
    picture: object
}

type Props = {
    contact: Contact,
    url: string,
    jobTitle: string,
    jobId: string,
    location: string,
    isSmallScreen: boolean,
    titleStyle: React.CSSProperties,
    buttonApplyDisabledStyle: React.CSSProperties,
    submitButtonStyle: React.CSSProperties,
}

function JobApplicationForm(props: Props) {
    const { contact, url, jobTitle, jobId, isSmallScreen, location, buttonApplyDisabledStyle, submitButtonStyle, titleStyle } = props;
    // Refs
    const recaptchaRef = React.useRef();

    // Inputs
    const [firstName, setFirstName] = React.useState(undefined);
    const [lastName, setLastName] = React.useState(undefined);
    const [email, setEmail] = React.useState(undefined);
    const [phoneNumber, setPhoneNumber] = React.useState(undefined);
    const [linkedin, setLinkedin] = React.useState(undefined);
    const [resumeFile, setResumeFile] = React.useState(undefined);
    const [message, setMessage] = React.useState(undefined);

    // Validations
    const [loading, setLoading] = React.useState(false);
    const [isErrorOnFormSubmitted, setIsErrorOnFormSubmitted] = React.useState(false);
    const [isPolicyAccepted, setIsPolicyAccepted] = React.useState(false);
    const [isInputsValid, setIsInputsValid] = React.useState(true);
    const [isRecaptchaValid, setIsRecaptchaValid] = React.useState(false);
    const [isRecaptchaDisplayed, setIsRecaptchaDisplayed] = React.useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

    const [isErrorActive, setIsErrorActive] = React.useState({
        firstName: false,
        lastName: false,
        email: false,
        linkedin: false,
        phoneNumber: false,
    });

    React.useEffect(() => {
        const inputsValid = validateInputs();
        setIsInputsValid(inputsValid);
    }, [firstName, lastName, email, phoneNumber, linkedin, resumeFile, isErrorActive.phoneNumber, isErrorActive.linkedin]);

    // Inputs handlers
    const handleFirstName = React.useCallback((value) => {
        setFirstName(value);
    }, []);

    const handleLastName = React.useCallback((value) => {
        setLastName(value);
    }, []);

    const handleEmail = React.useCallback((value) => {
        setEmail(value);
    }, [isErrorActive]);

    const handlePhoneNumber = React.useCallback((value) => {
        setPhoneNumber(value);
    }, [isErrorActive]);

    const handleLinkedin = React.useCallback((value) => {
        setLinkedin(value);
    }, [isErrorActive]);

    const handleResumeUpload = React.useCallback((file) => {
        setResumeFile(file);
    }, []);

    const handleMessage = React.useCallback((value) => {
        setMessage(value);
    }, []);

    // Inputs validators
    const validateFirstName = React.useCallback(() => {
        if (!firstName) {
            setIsErrorActive({ ...isErrorActive, firstName: true });
        } else {
            if (isErrorActive.firstName) {
                setIsErrorActive({ ...isErrorActive, firstName: false });
            }
        }
    }, [firstName, isErrorActive]);

    const validateLastName = React.useCallback(() => {
        if (!firstName) {
            setIsErrorActive({ ...isErrorActive, lastName: true });
        } else {
            if (isErrorActive.lastName) {
                setIsErrorActive({ ...isErrorActive, lastName: false });
            }
        }
    }, [lastName, isErrorActive]);

    const validateEmail = React.useCallback(() => {
        if (!email || !emailRegex.test(String(email).toLowerCase())) {
            setIsErrorActive({ ...isErrorActive, email: true });
        } else {
            if (isErrorActive.email) {
                setIsErrorActive({ ...isErrorActive, email: false });
            }
        }
    }, [email, isErrorActive]);

    const validatePhoneNumber = React.useCallback(() => {
        if (phoneNumber && !phoneNumber.match(phoneRegex)) {
            setIsErrorActive({ ...isErrorActive, phoneNumber: true });
        } else {
            if (isErrorActive.phoneNumber) {
                setIsErrorActive({ ...isErrorActive, phoneNumber: false });
            }
        }
    }, [phoneNumber, isErrorActive]);

    const validateLinkedin = React.useCallback(() => {
        if (linkedin && !linkedin.match('^https:\\/\\/[a-z]{2,3}\\.linkedin\\.com\\/.*$')) {
            setIsErrorActive({ ...isErrorActive, linkedin: true });
        } else {
            if (isErrorActive.linkedin) {
                setIsErrorActive({ ...isErrorActive, linkedin: false });
            }
        }
    }, [linkedin, isErrorActive]);

    const validateInputs = React.useCallback(() => {
        let linkedinOrResumeRequiredError = (linkedin === undefined || linkedin === "") && resumeFile === undefined;

        return firstName && lastName && email && !isErrorActive.phoneNumber && !isErrorActive.linkedin && !linkedinOrResumeRequiredError;
    }, [firstName, lastName, email, linkedin, phoneNumber, isErrorActive.phoneNumber, isErrorActive.linkedin, resumeFile]);

    // Form methods
    const sendForm = React.useCallback(async (fileUploadURL?: string) => {
        const data = {
            fields: {
                name: firstName.concat(' ').concat(lastName),
                stage: 'New',
                stage_status: 'Form apply',
                date: new Date().toISOString().slice(0, 10),
                resume: fileUploadURL ? [{ url: fileUploadURL.split("?")[0] }] : undefined,
                email,
                phone: phoneNumber,
                position: [jobId],
                owner: [contact.record_id],
                linkedin,
                message
            },
            meta: {
                title: jobTitle,
                link: url,
            }
        }

        try {
            const dataEncoded = Buffer.from(JSON.stringify(data)).toString("base64");

            const applyResponse = await axios.put(API_URL + `applyjob?d=${dataEncoded}`, { headers: { "Content-Type": "application/json" } });

            if (applyResponse.status === 404) {
                Bugsnag.notify(new Error('Failed to submit job application.'));
                setLoading(false);
                setIsErrorOnFormSubmitted(true);
            } else {
                setLoading(false);
                setIsFormSubmitted(true);
            }
        } catch (err) {
            Bugsnag.notify(new Error(err));
            setLoading(false);
            setIsErrorOnFormSubmitted(true);
        }
    }, [url, firstName, lastName, email, linkedin, phoneNumber, jobTitle, jobId, location, message]);

    const submitForm = React.useCallback(() => {
        setLoading(true);
        if (resumeFile) {
            return axios(API_URL + "presigned-url?fileName=" + resumeFile.name + "&fileType=" + resumeFile.type)
                .then(response => {
                    const fileUploadURL = response.data.fileUploadURL;
                    return axios({
                        method: "PUT",
                        url: fileUploadURL,
                        data: resumeFile,
                        headers: { "Content-Type": resumeFile.type }
                    })
                        .then(() => {
                            return sendForm(fileUploadURL);
                        })
                        .catch((err) => {
                            Bugsnag.notify(err);
                            setLoading(false);
                            setIsErrorOnFormSubmitted(true);
                        });
                });
        } else {
            return sendForm();
        }
    }, [resumeFile, sendForm]);

    // Policy and recaptcha
    const handlePolicyBox = React.useCallback(() => {
        setIsPolicyAccepted(!isPolicyAccepted);
        if (!isPolicyAccepted && !isRecaptchaDisplayed) {
            recaptchaRef.current.renderExplicitly();
            setIsRecaptchaDisplayed(true);
        } else {
            handleExpiredRecaptcha();
        }
    }, [isPolicyAccepted, isRecaptchaDisplayed]);

    const onVerifyRecaptcha = React.useCallback(() => {
        setIsRecaptchaValid(true);
    }, []);

    const handleExpiredRecaptcha = () => {
        setIsRecaptchaValid(false);
    };

    if (loading) {
        return (
            <JobApplicationFormWrapper>
                <div className="center">
                    <Icon className="csl-spin" icon={refresh} size={48} style={{ color: "#F26522" }} />
                </div>
            </JobApplicationFormWrapper>
        )
    }

    if (isFormSubmitted) {
        return (
            <JobApplicationFormWrapper id="job_application_form">
                <FormHeader style={{ marginBottom: 0 }}>
                    <Text content={TEXT.APPLICATION_SUBMITED_SUCCESS} {...titleStyle} color="green" />
                    <Text content={TEXT.APPLICATION_SUBMITED_SUCCESS_MESSAGE} fontSize="14px" color="rgba(0, 0, 0, 0.36)" />
                </FormHeader>
            </JobApplicationFormWrapper>
        )
    }

    if (isErrorOnFormSubmitted) {
        return (
            <JobApplicationFormWrapper id="job_application_form">
                <FormHeader style={{ marginBottom: 0 }}>
                    <Text content={TEXT.APPLICATION_SUBMITED_FAIL} {...titleStyle} color="red" />
                    <Text content={TEXT.APPLICATION_SUBMITED_ERROR} fontSize="14px" color="rgba(0, 0, 0, 0.36)" />
                </FormHeader>
            </JobApplicationFormWrapper>
        )
    }

    return (
        <JobApplicationFormWrapper id="job_application_form">
            <FormHeader>
                <Text content={TEXT.APPLY_THROUGH_FORM} fontSize="18px" />
                <Text content={TEXT.APPLY_THROUGH_FORM_DISCLAIMER} fontSize="12px" color="rgba(0, 0, 0, 0.36)" />
            </FormHeader>
            <Row>
                <Input
                    label={TEXT.FIRST_NAME_LABEL}
                    placeholder={TEXT.FIRST_NAME_PLACEHOLDER}
                    inputType="text"
                    iconPosition="right"
                    className="domain_search_input"
                    onChange={handleFirstName}
                    error={isErrorActive.firstName ? TEXT.REQUIRED_FIELD : undefined}
                    onBlur={validateFirstName}
                />
                <Input
                    label={TEXT.LAST_NAME_LABEL}
                    placeholder={TEXT.LAST_NAME_PLACEHOLDER}
                    inputType="text"
                    iconPosition="right"
                    className="domain_search_input"
                    onChange={handleLastName}
                    onBlur={validateLastName}
                    error={isErrorActive.lastName ? TEXT.REQUIRED_FIELD : undefined}
                />
            </Row>
            <Row>
                <Input
                    label={TEXT.EMAIL_LABEL}
                    placeholder={TEXT.EMAIL_PLACEHOLDER}
                    inputType="email"
                    iconPosition="right"
                    className="domain_search_input"
                    onChange={handleEmail}
                    onBlur={validateEmail}
                    error={isErrorActive.email ? TEXT.ERROR_EMAIL_VALIDATION : undefined}
                />
                <Input
                    label={TEXT.PHONE_LABEL}
                    placeholder={TEXT.PHONE_PLACEHOLDER}
                    inputType="text"
                    iconPosition="right"
                    onChange={handlePhoneNumber}
                    onBlur={validatePhoneNumber}
                    className="domain_search_input"
                    error={isErrorActive.phoneNumber ? TEXT.ERROR_PHONE_VALIDATION : undefined}
                />
            </Row>
            <Row>
                <Input
                    label={TEXT.LINKEDIN_LABEL}
                    placeholder={TEXT.LINKEDIN_PLACEHOLDER}
                    inputType="text"
                    iconPosition="right"
                    onChange={handleLinkedin}
                    onBlur={validateLinkedin}
                    className="domain_search_input"
                    error={isErrorActive.linkedin ? TEXT.ERROR_LINKEDIN_VALIDATION : undefined}
                />
            </Row>
            <Row>
                <FileUpload isSmallScreen={isSmallScreen} onFileUpload={handleResumeUpload} />
            </Row>
            <Row>
                <Input
                    label={TEXT.MESSAGE_LABEL}
                    inputType="textarea"
                    iconPosition="right"
                    className="domain_search_input"
                    onChange={handleMessage}
                />
            </Row>
            <Row>
                <CheckBox
                    disabled={!isInputsValid}
                    isChecked={isPolicyAccepted}
                    onCheck={handlePolicyBox}
                    labelText={<p style={{ fontSize: "14px", color: isInputsValid ? "rgba(0, 0, 0, 0.36)" : "rgba(0, 0, 0, 0.16)" }}>{TEXT.READ_AND_AGREED}&apos;s <a style={!isInputsValid ? { color: "rgba(0, 0, 0, 0.36)" } : {}} href="https://talentswipe.careers/privacy-policy">{TEXT.PRIVACY_POLICY}</a>.</p>} />
            </Row>
            <Row>
                <Reaptcha
                    ref={e => (recaptchaRef.current = e)}
                    sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                    onVerify={onVerifyRecaptcha}
                    size="normal"
                    explicit={true}
                    onExpire={handleExpiredRecaptcha}
                />
            </Row>
            <Row alignItems="center">
                {isRecaptchaValid && isInputsValid ?
                    <Button icon={<Icon icon={send} size={18} />} onClick={submitForm} title={TEXT.APPLY} className="domain_search_button" color="successWithBg" {...submitButtonStyle} width={isSmallScreen ? "100%" : "50%"} />
                    :
                    <Button disabled={true} icon={<Icon icon={send} size={18} />} title={TEXT.APPLY} {...buttonApplyDisabledStyle} width={isSmallScreen ? "100%" : "50%"} />}
            </Row>
        </JobApplicationFormWrapper>
    );
}

JobApplicationForm.defaultProps = {
    titleStyle: {
        fontSize: "18px",
        fontWeight: "400",
        color: "#0f2137",
        lineHeight: "1.75",
        mb: "0"
    },
    submitButtonStyle: {
        fontSize: ["13px", "14px"],
        fontWeight: "500",
        colors: "successWithBg",
        marginRight: '5px',
    },
    buttonApplyDisabledStyle: {
        fontSize: ["13px", "14px"],
        fontWeight: "500",
        backgroundColor: 'gray',
        color: 'white',
        borderColor: 'gray',
        marginRight: '5px',
    },
}

export default JobApplicationForm;