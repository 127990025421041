import TEXT from '../../../../../common/texts';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const spanStyle: React.CSSProperties = {
    display: "block",
    color: "#767676",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "10px"
};

const baseStyle: React.CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle: React.CSSProperties = {
    borderColor: '#2196f3'
};

const acceptStyle: React.CSSProperties = {
    borderColor: '#00e676'
};

const rejectStyle: React.CSSProperties = {
    borderColor: '#ff1744'
};

type Props = {
  onFileUpload: (file: File | undefined) => void,
  isSmallScreen: boolean,
}

function FileUpload(props: Props) {
  const { isSmallScreen, onFileUpload } = props;
  const {
      fileRejections,
      acceptedFiles,
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject
  } = useDropzone({ accept: ["application/pdf", "application/msword", "text/plain", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"], multiple: false });

  const [file, setFile] = React.useState<File>();
  const [isFileInvalid, setIsFileInvalid] = React.useState(false);

  const style = React.useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
  }), [
      isDragActive,
      isDragReject,
      isDragAccept
  ]);

  React.useEffect(() => {
      if (acceptedFiles.length) {
          setFile(acceptedFiles[0]);
          setIsFileInvalid(false);
          onFileUpload(acceptedFiles[0]);
      }
  }, [acceptedFiles]);

  React.useEffect(() => {
      if (fileRejections.length) {
          setFile(undefined);
          onFileUpload(undefined);
          setIsFileInvalid(true);
      }
  }, [fileRejections]);

  return (
      <div style={{ display: "flex", width: "100%", flexDirection: "column", marginBottom: "30px" }}>
          <label style={spanStyle} >Resume</label>
          <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {isFileInvalid ?
                  <p style={{ color: '#d850309c' }}>{TEXT.INVALID_FILE}</p> :
                  <p>{file ? file.name : (isSmallScreen ? TEXT.SELECT_RESUME : TEXT.DROP_RESUME)}</p>
              }
          </div>
      </div>
  );
}

export default FileUpload;