import styled from "styled-components";
import { deviceSize } from "../../../../../common/utils";

export const Container = styled.div`
  height: 100%;
  background-color: white;
  box-shadow: 0px 6px 15.98px 1.02px rgba(56, 86, 122, 0.1);
  border-radius: 10px;
  margin-top: 20px;
  padding: 40px;
  @media (max-width: ${deviceSize.tablet.px}) {
    border-radius: 0px;
    box-shadow: 0px 1px gray;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .domain_search_button {
    width: 100%;
    @media (min-width: 576px) {
      border-radius: 3px;
    }
    @media (max-width: 575px) {
      height: 52px;
      width: 100%;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  min-width: 250px;
`;

export const RoundWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-sizing: border-box;
  background: rgb(232, 230, 192);
  background: radial-gradient(circle, rgba(232, 230, 192, 1) 0%, rgba(199, 195, 134, 1) 100%);
  overflow: hidden;
`;

export const Title = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const SocialMediaButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 10px;
  @media (max-width: ${deviceSize.mobileL.px}) {
    justify-content: flex-start;
  }
`;

export const Information = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0;
  border-top: 1px solid #ddd;
`;

export const ContactSubHeader = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
`;