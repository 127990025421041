import styled from "styled-components";

export const JobApplicationFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 6px 15.98px 1.02px rgba(56, 86, 122, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    padding: 40px;
    @media (max-width: 768px) {
      margin-top: 10px;
      padding-top: 20px;
      padding-right: 30px;
      padding-left: 30px;
      border-radius: 0px;
      box-shadow: 0px 1px gray;
    }

    .center {
      margin: auto;
      width: 50%;
      max-width: 100%;
      min-width: 150px;
      position: relative;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
    }

    .csl-spin {
      animation: rotate 1s linear infinite;
    }
    
    @keyframes rotate {
      to {
        transform: rotate(360deg);
      }
    }
`;

export const FormHeader = styled.div`
  margin-bottom: 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .domain_search_input {
    flex-grow: 1;
    box-shadow: none;
    margin-right: 20px;
    margin-bottom: 30px;
    outline: none;
    @media (max-width: 768px) {
      margin-bottom: 5px;
      margin-right: 0px;
    }
    .field-wrapper {
      border: 0;
    }
    .field-wrapper,
    input {
      height: 100%;
      box-shadow: none;
      outline: none;
      padding: 0;
      :: placeholder {
        color: rgba(0, 0, 0, 0.36);
      }
    }
    input {
      font-size: 17px;
      padding-right: 10px;
      padding-left: 10px;
      text-shadow: none;
      border: 1px solid #ebebeb;
      &:focus {
        border-color: #ebebeb;
      }

      @media (min-width: 576px) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #f4f5f7;
        &:focus {
          border-color: #5268db;
        }
      }
      @media (max-width: 575px) {
        height: 52px;
        padding-left: 20px;
        margin-bottom: 20px;
      }
    }
  }
`;