import styled from "styled-components";

const JobWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;
    background-color: white;
    box-shadow: 0px 6px 15.98px 1.02px rgba(56, 86, 122, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;

    .post-card-avatar-small {
      width: 50px;
      height: 50px;
      margin: 0 7px 10px 0;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .post-card-avatar-small .company-image {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
}

  .post-card-avatar {
      width: 50px;
      height: 50px;
      margin: 0 8px 0px 8px;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  
  .post-card-avatar .company-image {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
  }

  .title {
    fontSize: 2rem,
    fontWeight: 500,
    color: #0f2137,
    letterSpacing: -0.025em,
    marginBottom: 5px,
    lineHeight: 1.31
  }

  .domain_search_input {
    flex-grow: 1;
    box-shadow: none;
    outline: none;
    .field-wrapper {
      border: 0;
    }
    .field-wrapper,
    input {
      height: 100%;
      box-shadow: none;
      outline: none;
    }
    input {
      font-size: 17px;
      padding-left: 50px;
      padding-right: 30px;
      text-shadow: none;
      border: 1px solid #ebebeb;
      &:focus {
        border-color: #ebebeb;
      }

      @media (min-width: 576px) {
        border-right: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #f4f5f7;
        &:focus {
          border-color: #f4f5f7;
        }
      }
      @media (max-width: 575px) {
        height: 52px;
        padding-left: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .domain_search_select {
    flex: 1;
    @media (max-width: 575px) {
      height: 52px;
      margin-bottom: 20px;
    }
    .select__control,
    .select-field__wrapper {
      height: 100%;
    }
    .select__control {
      padding: 0 15px 0 10px;
      box-shadow: none;
      position: relative;
      border-color: #ebebeb;
      @media (min-width: 576px) {
        border-color: #f4f5f7;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        &:before {
          content: "";
          position: absolute;
          width: 1px;
          height: 55%;
          background: #d9d9d9;
          display: block;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .select__placeholder {
        font-size: 17px;
        color: #0f2137;
      }
      .select__indicator {
        color: #0f2137;
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }

  .domain_search_button {
    width: 160px;
    @media (min-width: 576px) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    @media (max-width: 575px) {
      height: 52px;
      width: 100%;
    }
  }
`;

const Chip = styled.div`
    display: inline-flex;
    flex-direction: row;
    background-color: #f7f8fe;
    border: none;
    cursor: default;
    height: 20px;
    outline: none;
    padding: 0;
    font-size: 14px;
    color: #a2b0f9;
    font-family:"Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 14px;
    padding: 8px;
    margin: 2px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
`;

const ChipBlue = styled(Chip)`
  background-color: #f16522;
  color: #ffffff;
  margin-right: 2px;
  padding: 6px;
  height: 16px;
  font-size: 12px;
`

export { Chip, ChipBlue, JobWrapper };
