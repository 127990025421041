import React from "react";
import { Link, navigate } from "gatsby"
import Toggle from 'react-toggle'
import { useFlexSearch } from 'react-use-flexsearch'

import { Button, Container, FilterBar } from "@components/index";
import JobRow from './components/JobRow';
import SearchBar from './components/SearchBar';
import { Job } from "../../../common/types";
import Particles from "../../common/Particle";
import { JobSectionWrapper } from "./jobSection.style";
import { DeviceContext } from "../../../contexts/DeviceProvider";

const JOBS_PER_PAGE = 5;

type JobListProps = {
  data: Array<Job>,
  isSmallScreen: boolean,
  pageNumber: number
}

export function JobList({ data, isSmallScreen, pageNumber }: JobListProps) {
  let filteredDisplay = [];

  if (!pageNumber) {
    filteredDisplay = data.slice(0, JOBS_PER_PAGE);
  } else {
    filteredDisplay = data.slice(JOBS_PER_PAGE * Number(pageNumber), JOBS_PER_PAGE * Number(pageNumber + 1));
  }

  return filteredDisplay.map((entry, index) => <JobRow data={entry} key={index} isSmallScreen={isSmallScreen} />);
}

type JobListPaginationProps = {
  totalPages: number,
  currentPage: number,
  totalJobResults: number,
  onGetUrlLink: (direction: string, page?: number) => string
}

const MAX_PAGES = 3;

function JobListPagination({ totalPages, currentPage, totalJobResults, onGetUrlLink } : JobListPaginationProps) {
  const disabledPrev = currentPage ? "" : "disabled-link"
  const disabledNext = totalJobResults > 0 && ((totalJobResults > (currentPage + 1) * JOBS_PER_PAGE)) ? "" : "disabled-link";
  const generatedPageNumbers = React.useMemo(() => {

    if (currentPage < MAX_PAGES - 1) {
      const pagesArr = [...Array(MAX_PAGES).keys()];

      return (
        <>
        {pagesArr.map((page, key) => (
          <div key={key}>
            <Link to={onGetUrlLink('none', page)} rel="prev">
              <Button title={String(page + 1)} variant="textButton" colors="primary" style={{ marginRight: 10 }} className={currentPage === page ? "active_page_number" : "page_number"}/>
            </Link>
          </div>
        ))}
        <div>...</div>
        <div style={{ marginLeft: 10 }}>
            <Link to={onGetUrlLink('none', totalPages - 1)} rel="prev">
              <Button title={String(totalPages)} variant="textButton" colors="primary" style={{ marginRight: 10 }} className={currentPage === totalPages ? "active_page_number" : "page_number"}/>
            </Link>
          </div>
        </>
      )
    } else if (currentPage >= MAX_PAGES - 1 && currentPage <= totalPages - MAX_PAGES - 1) {
      const pagesArr = Array.from({ length: MAX_PAGES }, (_, k) => currentPage + k);
  
      return (
        <>
        <div>
            <Link to={onGetUrlLink('none', 0)} rel="prev">
              <Button title={String(1)} variant="textButton" colors="primary" style={{ marginRight: 10 }} className={currentPage === totalPages ? "active_page_number" : "page_number"}/>
            </Link>
          </div>
          <div style={{ marginRight: 10 }}>...</div>
        {pagesArr.map((page, key) => (
          <div key={key}>
            <Link to={onGetUrlLink('none', page)} rel="prev">
              <Button title={String(page + 1)} variant="textButton" colors="primary" style={{ marginRight: 10 }} className={currentPage === page ? "active_page_number" : "page_number"}/>
            </Link>
          </div>
        ))}
        <div>...</div>
        <div>
            <Link to={onGetUrlLink('none', totalPages - 1)} rel="prev">
              <Button title={String(totalPages)} variant="textButton" colors="primary" style={{ marginLeft: 10, marginRight: 10 }} className={currentPage === totalPages ? "active_page_number" : "page_number"}/>
            </Link>
          </div>
        </>
      )
    } else {
      const pagesArr = Array.from({ length: MAX_PAGES }, (_, k) => totalPages - 1 - k).reverse();

      return (
        <>
        <div>
            <Link to={onGetUrlLink('none', 0)} rel="prev">
              <Button title={String(1)} variant="textButton" colors="primary" style={{ marginRight: 10 }} className={currentPage === totalPages ? "active_page_number" : "page_number"}/>
            </Link>
          </div>
        <div style={{ marginRight: 10 }}>...</div>
        {pagesArr.map((page, key) => (
          <div key={key}>
            <Link to={onGetUrlLink('none', page)} rel="prev">
              <Button title={String(page + 1)} variant="textButton" colors="primary" style={{ marginRight: 10 }} className={currentPage === page ? "active_page_number" : "page_number"}/>
            </Link>
          </div>
        ))}
        </>
      )
    }
  }, [totalPages, currentPage, onGetUrlLink]);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <nav style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
      <div>
        <Link disabled={disabledPrev} className={`button ${disabledPrev}`} to={onGetUrlLink('backward')} rel="prev">
        <Button title="Prev" variant="textButton" style={{ marginRight: 10 }} className="page_number"/>
        </Link>
    </div>
    {generatedPageNumbers}
      <div>
        <Link disabled={disabledNext} className={`button ${disabledNext}`} to={onGetUrlLink('forward')} rel="next">
          <Button disabled title="Next" variant="textButton" className="page_number"/>
          </Link>
      </div>
  </nav>
  )
}

type JobSectionProps = {
  url: string,
  jobList: Array<JobType>,
  searchData: any
}

const JobSection = ({ url, jobList, searchData }: JobSectionProps) => {
  const isSmallScreen = React.useContext(DeviceContext);

  const [isFilterToggleEnabled, setFilterToggleEnabled] = React.useState(false);

  const positionList = [...new Set(jobList.map(job => job.position))];
  const locationList = [...new Set(jobList.reduce((acc, curr) => { return acc.concat(...curr.location) }, []))];

  const [results, setResults] = React.useState([]);
  const [searchedText, setSearchedText] = React.useState(undefined);
  const [pageNumber, setPageNumber] = React.useState(undefined);
  const [locationFilter, setLocationFilter] = React.useState(undefined);
  const [positionFilter, setPositionFilter] = React.useState(undefined);
  const [experienceFilter, setExperienceFilter] = React.useState(undefined);
  const [telecomuttingFilter, setTelecomuttingFilter] = React.useState(undefined);
  const [jobStatusFilter, setJobStatusFilter] = React.useState(undefined);
  const [employmentFilter, setEmploymentFilter] = React.useState(undefined);
  const [totalPages, setTotalPages] = React.useState(undefined);
  const searchResult = useFlexSearch(searchedText, searchData.index, searchData.store);

  React.useEffect(() => {
    const currentURL = new URL(url);
    const searchQuery = new URLSearchParams(currentURL.search);
    const query = searchQuery.get('q');
    const page = searchQuery.get('p');
    const location = searchQuery.get('location');
    const position = searchQuery.get('position');
    const experience = searchQuery.get('experience');
    const telecomutting = searchQuery.get('telecomutting');
    const status = searchQuery.get('status');
    const employment = searchQuery.get('employment');

    setLocationFilter(location);
    setPositionFilter(position);
    setExperienceFilter(experience);
    setTelecomuttingFilter(telecomutting);
    setJobStatusFilter(status);
    setEmploymentFilter(employment);
    setSearchedText(query);
    setPageNumber(Number(page));
  }, [url]);

  const filterResults = React.useCallback((results) => {
    let filteredResults = [...results];

    filteredResults = filteredResults.filter(entry => {
      if (locationFilter && !entry.location.includes(locationFilter)) return false;
      if (positionFilter && (positionFilter !== entry.position)) return false;
      if (experienceFilter && !entry.experience.includes(experienceFilter)) return false;
      if (telecomuttingFilter && !entry.telecomutting.includes(telecomuttingFilter)) return false;
      if (jobStatusFilter && (jobStatusFilter !== entry.status)) return false;
      if (employmentFilter && !entry.employment.includes(employmentFilter)) return false;

      return true;
    });

    setTotalPages(Math.ceil(filteredResults.length / JOBS_PER_PAGE));

    return filteredResults;
  }, [locationFilter, positionFilter, experienceFilter, telecomuttingFilter, jobStatusFilter, employmentFilter])

  // This should be call if in query I have searched text
  React.useEffect(() => {
    if (searchResult.length > 0) {
      const filteredResults = filterResults(searchResult);
      setResults(filteredResults);
        // fetch(new URL(url).origin + searchData.publicIndexURL)
        //   .then(response => response.json())
        //   .then(data => setSearchDataIndex(data))
        //   .then(() => fetch(new URL(url).origin + searchData.publicStoreURL))
        //   .then(response => response.json())
        //   .then(data => setSearchDataStore(data));
    } else {
      const filteredResults = filterResults(jobList);
      setResults(filteredResults);
    }
  }, [searchData, searchedText, jobList, filterResults]);

  const getUrlLink = React.useCallback((direction: string, page?: number) => {
    let baseLink = '/?';
    const params = new URLSearchParams();

    if (direction === 'none' && page) {
      params.append('p', String(page));
    }

    if (direction === 'forward') {
      params.append('p', pageNumber + 1);
    }
    if (direction === 'backward' && pageNumber > 1) {
      pageNumber && params.append('p', String(pageNumber - 1));
    }

    searchedText && params.append('q', searchedText);
    locationFilter && params.append('location', locationFilter);
    positionFilter && params.append('position', positionFilter);
    experienceFilter && params.append('experience', experienceFilter);
    telecomuttingFilter && params.append('telecomutting', telecomuttingFilter);
    jobStatusFilter && params.append('status', jobStatusFilter);
    employmentFilter && params.append('employment', employmentFilter);

    if (Array.from(params).length === 0) {
      baseLink = '/';
    }

    return baseLink + params.toString();
  }, [searchedText, locationFilter, positionFilter, experienceFilter, telecomuttingFilter, jobStatusFilter, employmentFilter, pageNumber]);

  const handleNavigation = React.useCallback(() => {
    let baseLink = '/?';
    const params = new URLSearchParams();

    searchedText && params.append('q', searchedText);
    pageNumber && params.append('p', pageNumber);
    locationFilter && params.append('location', locationFilter);
    positionFilter && params.append('position', positionFilter);
    experienceFilter && params.append('experience', experienceFilter);
    telecomuttingFilter && params.append('telecomutting', telecomuttingFilter);
    jobStatusFilter && params.append('status', jobStatusFilter);
    employmentFilter && params.append('employment', employmentFilter);

    if (Array.from(params).length === 0) {
      baseLink = '/';
    }

    navigate(baseLink + params.toString());
  }, [searchedText, locationFilter, positionFilter, experienceFilter, telecomuttingFilter, jobStatusFilter, employmentFilter]);

  React.useEffect(() => {
    handleNavigation();
  }, [handleNavigation]);

  const handleSearch = React.useCallback((value) => {
    setSearchedText(value);
  }, []);

  const handleLocation = React.useCallback((location) => {
    setLocationFilter(location.value);
  }, []);

  const handlePosition = React.useCallback((entry) => {
    setPositionFilter(entry.value);
  }, []);

  const handleExperience = React.useCallback((entry) => {
    setExperienceFilter(entry.value);
  }, []);

  const handleTelecomutting = React.useCallback((entry) => {
    setTelecomuttingFilter(entry.value);
  }, []);

  const handleJobStatus = React.useCallback((entry) => {
    setJobStatusFilter(entry.value);
  }, []);

  const handleEmployment = React.useCallback((entry) => {
    setEmploymentFilter(entry.value);
  }, []);

  const handleFiltersToggle = React.useCallback(() => {
    setFilterToggleEnabled(!isFilterToggleEnabled);
  }, [isFilterToggleEnabled]);

  return (
    <JobSectionWrapper>
      <Particles />
      <Container className="row">
        <SearchBar value={searchedText} onSearch={handleSearch} location={locationFilter} locationList={locationList} onLocationChange={handleLocation} isSmallScreen={isSmallScreen} />
        {isSmallScreen && (
          <label style={{ display: "flex", justifyContent: "space-between", paddingRight: 10, paddingLeft: 10, marginBottom: 15 }}>
            <span style={{ alignSelf: "center", fontSize: "14px" }} className='label-text'>Show filters</span>
            <Toggle
              icons={false}
              defaultChecked={isFilterToggleEnabled}
              onChange={handleFiltersToggle} />
          </label>
        )}
        {(!isSmallScreen || (isSmallScreen && isFilterToggleEnabled)) &&
          <FilterBar
            positionList={positionList}
            employment={employmentFilter}
            position={positionFilter}
            experience={experienceFilter}
            telecomutting={telecomuttingFilter}
            jobStatus={jobStatusFilter}
            onEmploymentChange={handleEmployment}
            onPositionChange={handlePosition}
            onExperienceChange={handleExperience}
            onTelecomuttingChange={handleTelecomutting}
            onJobStatusChange={handleJobStatus}
          />}
        <JobList
          pageNumber={pageNumber}
          data={results}
          isSmallScreen={isSmallScreen}
        />
        <JobListPagination
          currentPage={pageNumber}
          totalPages={totalPages}
          totalJobResults={results.length}
          onGetUrlLink={getUrlLink}
        />
      </Container>
    </JobSectionWrapper>
  );
};

export default JobSection;
