import React from "react";
import Icon from "react-icons-kit";
import { search } from "react-icons-kit/feather/search";

import { Box, Button, Input, Select } from "@components/index";
import { SearchWrapper } from "./search.style";
import TEXT from "../../../../../common/texts";

const SearchBar = ({ locationList, location, value, button, onSearch, onLocationChange, isSmallScreen }) => {
  const locationOptions = React.useMemo(() => {
    const mapedOptions = locationList.map(location => ({ value: location, label: location }));
    return [{ value: null, label: '-- All locations --' }, ...mapedOptions];
  }, [locationList]);

  if (isSmallScreen) {
    return (
      <>
        <Box className="row">
          <SearchWrapper>
            <Input
              inputType="text"
              placeholder={TEXT.SEARCH_PLACEHOLDER_MOBILE}
              iconPosition="right"
              className="domain_search_input"
              aria-label="search"
              onChange={onSearch}
              icon={<Icon icon={search} size={24} style={{ color: "#5268db" }} />}
              value={value}
            />
          </SearchWrapper>
        </Box>
        <SearchWrapper>
          <Select value={{ value: location, label: location || '-- All locations --' }} options={locationOptions} onChange={onLocationChange} placeholder="Location" className="domain_search_select" aria-label="select options" />
        </SearchWrapper>
      </>
    );
  }

  return (
    <Box>
      <SearchWrapper>
        <Input
          inputType="text"
          placeholder={TEXT.SEARCH_PLACEHOLDER_WEB}
          iconPosition="right"
          className="domain_search_input"
          aria-label="search"
          onChange={onSearch}
          value={value}
        />
        <Select value={{ value: location, label: location || '-- All locations --' }} options={locationOptions} onChange={onLocationChange} placeholder="Location" className="domain_search_select" aria-label="select options" />
        <Button {...button} icon={<Icon icon={search} size={24} />} className="domain_search_button" />
      </SearchWrapper>
    </Box>
  )
};

SearchBar.defaultProps = {
  button: {
    title: "Search",
    type: "button",
    fontSize: "18px",
    fontWeight: "500",
    color: '#5d646d',
    border: '1px solid #eeeeee',
    pl: "22px",
    pr: "22px",
    colors: "primary",
    iconPosition: "left"
  }
};

export default SearchBar;
