import React from 'react';
import PropTypes from "prop-types";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Icon from "react-icons-kit";
import { GatsbyImage } from "gatsby-plugin-image"

import { handPointerO } from "react-icons-kit/fa/handPointerO";
import { ic_phone } from "react-icons-kit/md/ic_phone";
import { ic_email } from "react-icons-kit/md/ic_email";
import { linkedin } from "react-icons-kit/fa/linkedin";

import { Container, Header, RoundWrapper, Title, SocialMediaButtons, Information, ContactSubHeader } from './jobContactWidget.style';
import { Button, Text } from '../../../../../components';

function JobContactWidget(props) {
    const phone = "tel:+".concat(props.contact.data.phone);
    const email = "mailto:".concat(props.contact.data.email);
    return (
        <Container>
            <Title>
                <Text content={`Have any questions? Talk to a consultant`} {...props.bigTitleStyle} />
            </Title>
            <Header>
                <RoundWrapper>
                    <GatsbyImage imgStyle={{ borderRadius: '100%', width: 50, height: 50}} image={props.contact.data.picture.localFiles[0].childImageSharp.gatsbyImageData} alt={props.contact.data.name}/>
                </RoundWrapper>
                <ContactSubHeader>
                    <Title>
                        <Text content={props.contact.data.name} {...props.titleStyle} />
                    </Title>
                    <SocialMediaButtons>
                        {props.contact.data.phone && (
                            <a href={phone}>
                                <Button icon={<Icon icon={ic_phone} size={26} />} {...props.buttonSocialStyle} />
                            </a>
                        )}
                        <a href={email}>
                            <Button icon={<Icon icon={ic_email} size={26} />} {...props.buttonSocialStyle} />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={props.contact.data.linkedin}>
                            <Button icon={<Icon icon={linkedin} size={26} />} {...props.buttonSocialStyle} />
                        </a>
                    </SocialMediaButtons>
                    <Information>
                        <Text content={`Posted on ${new Date(props.date).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}`} {...props.textStyle} />
                    </Information>
                    {props.status === "On-hold" && (
                        <Information>
                            <Text content={`The applications are currently suspended on this role, you can try again in other day.`} {...props.textStyle} />
                        </Information>
                    )}
                    {!props.isSmallScreen && (
                        <AnchorLink href="#job_application_form" offset="70">
                            {props.status === "Open" && <Button icon={<Icon icon={handPointerO} size={18} />} title="I'M INTERESTED" className="domain_search_button" {...props.buttonApplyStyle} />}
                            {props.status === "Closed" && <Button disabled title="CLOSED" className="domain_search_button" {...props.buttonApplyDisabledStyle} />}
                            {props.status === "On-hold" && <Button disabled title="ON HOLD" className="domain_search_button" {...props.buttonApplyOnHoldStyle} />}
                        </AnchorLink>
                    )}
                </ContactSubHeader>
            </Header>
        </Container>
    );
}

JobContactWidget.propTypes = {
    contact: PropTypes.object,
    location: PropTypes.string,
    date: PropTypes.string,
    titleStyle: PropTypes.object,
    bigTitleStyle: PropTypes.object,
    buttonSocialStyle: PropTypes.object,
    buttonApplyStyle: PropTypes.object,
    textStyle: PropTypes.object,
    isSmallScreen: PropTypes.bool,
    status: PropTypes.string,
    buttonApplyDisabledStyle: PropTypes.object
};

JobContactWidget.defaultProps = {
    bigTitleStyle: {
        fontSize: "18px",
        fontWeight: "400",
        color: "#0f2137",
        lineHeight: "1.75",
        mb: "6"
    },
    titleStyle: {
        fontSize: "18px",
        fontWeight: "400",
        color: "#0f2137",
        lineHeight: "1.75",
        mb: "0"
    },
    buttonSocialStyle: {
        fontSize: ["13px", "14px"],
        fontWeight: "500",
        colors: "tertiaryWithBg",
        marginRight: '5px',
    },
    buttonApplyStyle: {
        fontSize: ["13px", "14px"],
        fontWeight: "500",
        colors: "successWithBg",
        marginRight: '5px',
    },
    buttonApplyDisabledStyle: {
        fontSize: ["13px", "14px"],
        fontWeight: "500",
        colors: "disabled",
        marginRight: '5px',
    },
    buttonApplyOnHoldStyle: {
        backgroundColor: 'yellow',
        color: 'white',
        borderColor: 'yellow',
        fontSize: ["13px", "14px"],
        fontWeight: "500",
        colors: "disabled",
        marginRight: '5px',
    },
    textStyle: {
        fontSize: "16px",
        color: "#343d48cc",
        lineHeight: "1.75",
        mb: "0"
    }
};

export default JobContactWidget;

