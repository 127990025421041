import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import {ic_format_list_bulleted} from 'react-icons-kit/md/ic_format_list_bulleted'
import { ic_place } from 'react-icons-kit/md/ic_place';
import { ic_assignment } from 'react-icons-kit/md/ic_assignment';
import {ic_account_balance_wallet} from 'react-icons-kit/md/ic_account_balance_wallet'
import { ic_business } from 'react-icons-kit/md/ic_business';
import { cubes } from 'react-icons-kit/fa/cubes'
import { GatsbyImage } from "gatsby-plugin-image"
import { ic_lock_outline } from 'react-icons-kit/md/ic_lock_outline'

import { Button, Container, Text, Video } from "@components/index";
import {
  JobContentViewWrapper,
  TagsWrapper,
  Header,
  JobDescriptionContent,
  JobContent
} from "./jobContentView.style";
import JobApplicationForm from "../../../containers/jobs/JobApplicationForm";
import JobContactWidget from "./components/JobContactWidget";
import { DeviceContext } from "../../../contexts/DeviceProvider";
import { JobList } from "../JobSection/JobSection";

interface SalaryRangeType {
  min: number,
  max: number,
  currency: string,
  category: string,
  public: boolean,
}

function SalaryRange(props: SalaryRangeType) {
  if (!props.public) {
    return null;
  }

  if (props.min && props.max) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginTop: 2, marginBottom: 4, alignItems: 'center', textAlign: 'center' }}>
        <Text content={`${props.min} - ${props.max} ${props.currency} / month (${props.category})`} fontSize={["0.9em", "1em"]} color="#343d48cc" lineHeight="1.2" mb="0" />
      </div>
    )
  }

  if (props.min) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginTop: 2, marginBottom: 4, alignItems: 'center', textAlign: 'center' }}>
        <Text content={`from ${props.min} ${props.currency} / month (${props.category})`} fontSize={["0.9em", "1em"]} color="#343d48cc" lineHeight="1.2" mb="0" />
      </div>
    )
  }

  if (props.max) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginTop: 2, marginBottom: 4, alignItems: 'center', textAlign: 'center' }}>
        <Text content={`up to ${props.max} ${props.currency} / month (${props.category})`} fontSize={["0.9em", "1em"]} color="#343d48cc" lineHeight="1.2" mb="0" />
      </div>
    )
  }

  return null;
}

const Keywords = React.memo((props) => {
  return props.values.map((value, index) =>
    <Link to={`/?q=${value}`} key={index}> <Button title={value} className="domain_search_button" variant="outlined" color="primary" /></Link>)
});

Keywords.displayName = "Keywords";

Keywords.propTypes = {
  values: PropTypes.array
}

const JobContentView = ({ siteUrl, data, descStyle, descStyleBold, titleStyle }) => {
  const otherJobsSuggestionsQuery = useStaticQuery(graphql`
  {
    allAirtable(
      filter: {table: {eq: "Positions 📌"}}
      sort: {order: [DESC, DESC], fields: [data___status, data___date]}
      limit: 6
    ) {
      nodes {
        data {
          record_id
          location
          country
          company {
            data {
              name
              logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 100)
                  }
                }
              }
              video
              image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 677)
                  }
                }
              }
              brand_consent
            }
          }
          title
          telecomutting
          status
          keywords
          experience
          date
          employment
        }
      }
    }
  }
`);
  
  const otherJobsSuggestions = React.useMemo(() => {
    const list = otherJobsSuggestionsQuery.allAirtable.nodes.map(node => ({
      ...node.data,
      slug: `/${node.data.location[0].toLowerCase()}/${node.data.date}/${node.data.title.toLowerCase().replace(/[/+ #]/ig, '-')}-${node.data.record_id.substr(node.data.record_id.length - 6)}`,
      company: {
        ...node.data.company[0].data
      }
    })).filter(job => job.record_id !== data.id);

    if (list.length === 6) {
      list.pop();
      return list;
    }

    return list;
  }, [otherJobsSuggestionsQuery]);

  const isSmallScreen = React.useContext(DeviceContext);
  return (
    <JobContentViewWrapper>
      <Container className="container" noGutter={isSmallScreen}>
        <JobContent>
          <Header>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="post-card-avatar">
                {!data.company.brand_consent ?
                  <Icon icon={ic_lock_outline} size={28} />
                  :
                  <GatsbyImage imgStyle={{ borderRadius: '100%'}} className="company-image" image={data.company.logo.localFiles[0].childImageSharp.gatsbyImageData} alt={data.company.name}/>
                  }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "2em", marginLeft: isSmallScreen ? "0" : "1em" }}>
                <Text content={data.title} {...titleStyle} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {data.company && <Text content={!data.company.brand_consent ? "PRIVATE COMPANY" : data.company.name.toUpperCase()} {...descStyle} />}
                  {!isSmallScreen && data.company && !data.company.brand_consent && <Text content="(Apply or contact us to unlock)" style={{ marginBottom: "0px", fontSize: "0.9em", lineHeight: "1.2", marginLeft: "0.5em", color: "#5268db" }} />}
                </div>
              </div>
          </div>
          <TagsWrapper>
              <Keywords values={data.keywords} />
            </TagsWrapper>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '2em', backgroundColor: '#f6f7fd', padding: '1em', borderRadius: '12px' }}>
            <div style={{ display: 'flex', flex: isSmallScreen ? 2 : 1, flexDirection: 'column', justifyContent: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginBottom: 8, alignItems: 'center', textAlign: 'center' }}>
                  <Icon icon={cubes} size={18} style={{ color: "#5268db", marginRight: 4 }} />
                  <Text content='Experience level' {...descStyleBold} />
                </div>
                {isSmallScreen && (
                  <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginTop: 2, marginBottom: 8, alignItems: 'center', textAlign: 'center' }}>
                    <Text content={data.experience.join(', ')} {...descStyle} />
                  </div>
                )}
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginBottom: 8, alignItems: 'center', textAlign: 'center' }}>
                  <Icon icon={ic_place} size={18} style={{ color: "#5268db", marginRight: 4 }} />
                  <Text content='Location' {...descStyleBold} />
                </div>
                {isSmallScreen && (
                  <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginTop: 2, marginBottom: 8, alignItems: 'left', textAlign: 'left' }}>
                    {data.location[0] === 'Full-Remote' ?
                      <Text content={`${data.telecomutting} (${data.country})`} {...descStyle} /> :
                      <Text content={`${data.telecomutting} in ${data.country} (With offices in ${data.location.join(', ')})`} {...descStyle} />}
                  </div>
                )}
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginBottom: 8, alignItems: 'center', textAlign: 'center' }}>
                  <Icon icon={ic_assignment} size={18} style={{ color: "#5268db", marginRight: 4 }} />
                  <Text content='Employment type' {...descStyleBold} />
                </div>
                {isSmallScreen && (
                  <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginTop: 2, marginBottom: 8, alignItems: 'center', textAlign: 'center' }}>
                    <Text content={data.employment} {...descStyle} />
                  </div>
                )}
                {data.salary.public && (
                  <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginBottom: 8, alignItems: 'center', textAlign: 'center' }}>
                    <Icon icon={ic_account_balance_wallet} size={18} style={{ color: "#5268db", marginRight: 4 }} />
                    <Text content='Salary' {...descStyleBold} />
                </div>
                )}
                {isSmallScreen && (
                  <SalaryRange {...data.salary} />
                )}
              </div>
              {!isSmallScreen && (
              <div style={{ display: 'flex', flex: isSmallScreen ? 1 : 1.5, flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginBottom: 8, alignItems: 'center', textAlign: 'center' }}>
                    <Text content={data.experience.join(', ')} {...descStyle} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginBottom: 8, alignItems: 'left', textAlign: 'left' }}>
                    {data.location[0] === 'Full-Remote' ?
                      <Text content={`${data.telecomutting} (${data.country})`} {...descStyle} /> :
                      <Text content={`${data.telecomutting} in ${data.country} (With offices in ${data.location.join(', ')})`} {...descStyle} />}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginRight: 6, marginBottom: 8, alignItems: 'center', textAlign: 'center' }}>
                    <Text content={data.employment} {...descStyle} />
                  </div>
                  <SalaryRange {...data.salary} />
              </div>
              )}
            </div>
            <div style={{ backgroundColor: '#f6f7fd', padding: '1em', borderRadius: '12px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                <Icon icon={ic_business} size={18} style={{ color: "#5268db", marginRight: 4 }} />
                <Text content="About the company" />
              </div>
              {data.company && <Text content={data.company.description.childMarkdownRemark.rawMarkdownBody} {...descStyle} />}
              {data.company.image && <GatsbyImage className="image" image={data.company.image.localFiles[0].childImageSharp.gatsbyImageData} alt={data.company.name} />}
              {data.company.video && <Video videoSrcURL={data.company.video} videoTitle={data.company.name} isSmallScreen={isSmallScreen} />}
            </div>
          </Header>
          <JobDescriptionContent>
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </JobDescriptionContent>
          {isSmallScreen && <JobContactWidget contact={data.contact} location={data.location} date={data.date} status={data.status} isSmallScreen={isSmallScreen} />}
          {data.status === "Open" && <JobApplicationForm contact={data.contact.data} url={`${siteUrl}${data.slug}`} location={data.location} jobTitle={data.title} jobId={data.id} isSmallScreen={isSmallScreen} />}
        </JobContent>
        {!isSmallScreen && <JobContactWidget contact={data.contact} location={data.location} date={data.date} status={data.status} isSmallScreen={isSmallScreen} />}
      </Container>
      <div style={{ maxWidth: '1170px', width: '100%', margin: 'auto', paddingLeft: '30px', paddingRight: '30px', marginBottom: '30px' }}>
        <div style={{ marginTop: isSmallScreen ? '40px' : '100px', marginBottom: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ flexDirection: 'column'}}>
            <Text content="Not convinced yet?" {...titleStyle} />
            <Text content="Check out other similar jobs." />
          </div>
          {!isSmallScreen && (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Link to='/'>
                <Button icon={<Icon icon={ic_format_list_bulleted} size={20} />} size={20} title="View all jobs" className="domain_search_button" variant="outlined" color="headingColor" />
              </Link>
            </div>
          )}
        </div>
        <JobList
          pageNumber={0}
          data={otherJobsSuggestions}
          isSmallScreen={isSmallScreen}
        />
          {isSmallScreen && (
            <Link to='/' style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', justifyContent: 'center' }}>
                <Text content="View all jobs" style={{ marginRight: '8px', color: 'black', alignSelf: 'center'  }} />
                <Icon icon={ic_format_list_bulleted} size={20} style={{ color: 'black' }} />
              </Link>
          )}
      </div>
    </JobContentViewWrapper >
  );
};

JobContentView.propTypes = {
  data: PropTypes.object,
  titleStyle: PropTypes.object,
  descStyle: PropTypes.object,
  content: PropTypes.any,
};

JobContentView.defaultProps = {
  titleStyle: {
    fontSize: ["30px", "30px", "34px", "38px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    mb: "0"
  },
  descStyle: {
    fontSize: ["0.9em", "1em"],
    color: "#343d48cc",
    lineHeight: "1.2",
    mb: "0"
  },
  descStyleBold: {
    fontSize: ["0.9em", "1em"],
    fontWeight: "#0f2137",
    color: "black",
    lineHeight: "1.2",
    mb: "0"
  },
};

export default JobContentView;
