import styled from "styled-components";
import { deviceSize } from "../../../common/utils";

const JobContentViewWrapper = styled.section`
  padding-top: 140px;
  padding-bottom: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 6px 15.98px 1.02px rgba(56, 86, 122, 0.1);
  border-radius: 10px;
  @media (max-width: 990px) {
    padding-top: 180px;
    padding-bottom: 60px;
    min-height: auto;
  }
  @media (max-width: 767px) {
    padding-top: 130px;
    padding-bottom: 20px;
    min-height: auto;
  }

  @media only screen and (max-width: ${deviceSize.tablet.px}) {
    padding-top: 70px;
    background: none;
    padding-bottom: 0px;
  }

  li {
    list-style-type: disc;
    margin-left: 20px;
  }

  .post-card-avatar {
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    margin: 4px 7px 10px 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #ebebeb;
  }

  .video {
    width: 100%,
    margin-top: 20px;
  }

  .image {
    margin-top: 20px;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .post-card-avatar .company-image {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
  }

  .container {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: ${deviceSize.tablet.px}) {
      flex-direction: column;
    }
  }

  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 767px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .row {
    position: relative;
    z-index: 1;
  }
  
  .active_contact {
    width: 100%;
    position: absolute;
    top: 0;
  }

  .button__wrapper {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .reusecore__button {
      &.outlined {
        border-color: rgba(82, 104, 219, 0.2);
      }
    }
  }
`;

export const JobContent = styled.div`
  margin-right: 20px;
  @media only screen and (max-width: ${deviceSize.tablet.px}) {
    margin-right: 0px;
  }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 40px;
    padding-bottom: 36px;
    box-shadow: 0px 6px 15.98px 1.02px rgba(56, 86, 122, 0.1);
    border-radius: 10px;
    background-color: white;
    @media (max-width: ${deviceSize.tablet.px}) {
      border-radius: 0px;
      box-shadow: 0px 1px gray;
      padding: 0px;
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 20px;
    }
`;

export const JobDescriptionContent = styled(Header)`
  @media (max-width: ${deviceSize.tablet.px}) {
    padding-bottom: 40px;
    margin-top: 1px;
  }
`;

const TagsWrapper = styled.div`
  margin-bottom: 2em;
  @media (max-width: ${deviceSize.tablet.px}) {
    justify-content: center;
  }
  .domain_search_button {
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 0.9em;
    padding-left: 0px;
    padding-right: 0px;
    min-height: auto;
  }
`;

export { TagsWrapper, JobContentViewWrapper };
